<template>
    <div class="content-box">
      <b-form @submit.prevent="onSubmit">

              <b-row>
                <b-col cols-lg="6">

                  <b-form-group
                    :class="{
                      row,
                      error: v$.employeeSelect.$errors.length,
                    }"
                    :label="$t('forms.employee')"
                    label-for="employeeSelect"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <custom-select
                      id="employeeSelect"
                      :value="employeeSelect"
                      :options="employees"
                      resource="$Users"
                      resourceType="employee"
                      @input="setEmployee($event)"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.employeeSelect.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>
                  <b-form-group
                    :class="{
                      row,
                      error: v$.skillSelect.$errors.length,
                    }"
                    :label="$t('forms.skill')"
                    label-for="skillSelect"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <custom-select
                      id="skillSelect"
                      :value="skillSelect"
                      :options="skills"
                      resource="$Skills"
                      resourceType="skill"
                      @input="setSkill($event)"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.skillSelect.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>

                  <b-form-group
                    :class="{ row, error: v$.skillMatrix.level.$errors.length }"
                    :label="$t('forms.level')"
                    label-for="level"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <b-form-input v-model="skillMatrix.level" id="level" />
                    <div
                      class="input-errors"
                      v-for="error of v$.skillMatrix.level.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>

                  <b-form-group

                  class="row"
                  :label="$t('forms.expiryDate')"
                  label-for="expiryDate"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <datepicker
                    id="expiryDate"
                    v-model="skillMatrix.expiryDate"
                    :enableTimePicker="false"
                    class="form-input"
                  />

                </b-form-group>



                </b-col>
                <b-col cols-lg="6">

                </b-col>
              </b-row>

      </b-form>
    </div>
  </template>

  <script>
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import ResourceUtils from "@/mixins/ResourceUtils";
  import ListUtils from "@/mixins/ListUtils";
  import "vue3-date-time-picker/dist/main.css";
  import Datepicker from "vue3-date-time-picker";
  import CustomSelect from "@/components/Inputs/CustomSelect.vue";

  export default {
    components: {
      CustomSelect,
      Datepicker
    },
    props: {
      skillMatrixObject: { Object, required: true },
      action: { type: String, default: null },
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.skillSelect = this.skillMatrix.skillSelect
        this.employeeSelect = this.skillMatrix.employeeSelect
        if (this.employeeSelect && this.employees.length === 0) {
          this.employees.push(this.employeeSelect)
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        employeeSelect: { required },
        skillSelect: { required },
        skillMatrix: {
          level: { required }
        },
      };
    },
    data() {
      return {
        skillMatrix: this.skillMatrixObject,
        employeeSelect: {},
        employees: [],
        skills: [],
        skillSelect: {}
    };
    },
    watch: {
      action() {
        this.onSubmit();
      },
    },
    methods: {
      setEmployee($event) {
        this.employeeSelect = $event
      },
      setSkill($event) {
        this.skillSelect = $event
      },
      async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {

                if (this.employeeSelect) {
                    this.skillMatrix.employee = this.employeeSelect.id
                }
                if (this.skillSelect) {
                    this.skillMatrix.skill = this.skillSelect.id
                }
                if (this.action === "add") {
                    this.create(
                        this.$SkillMatrices,
                        this.skillMatrix,
                        this.$t("messages.skillCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$SkillMatrices,
                        this.skillMatrix.id,
                        this.skillMatrix,
                        this.$t("messages.skillUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
      success() {
        if (this.$route.params.employeeId) {
          this.$router.go(0);
        }

        this.$router.push(`/skill-matrix`);
      },
      error() {
        this.$emit("clearAction");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
  </style>

