<template>
  <div class="add-new-holiday">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.skillMatrixCreate')"
      :title="$t('messages.skillMatrixCreate')"
      @submitPressed="submitPressed"
    />
    <skill-matrix-form v-if="($route.params.employeeId && employee) || typeof $route.params.employeeId === 'undefined'" :skillMatrixObject="skillMatrix" :action="action" @clearAction="action = null"/>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import SkillMatrixForm from './components/SkillMatrixForm.vue'

export default {
  components: {
    TitleBar,
    SkillMatrixForm
  },
  data() {
    return {
      action: null,
      skillMatrix: {},
      employee: null
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.employeeId;
      if (id) {
        this.$Users.getResource({ id }, 'employee_list').then((response) => {
          this.employee = response.data
          this.skillMatrix.employee = response.data
          this.skillMatrix.employeeSelect = {
            label: (response.data.number ? response.data.number + ' ' : '') + response.data.givenName+' '+response.data.familyName,
            id: response.data['@id']
          }
        });
      }
    },
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
